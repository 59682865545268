<template>
  <div>
    <div v-if="!loading" class="justify-center d-flex" style="font-size: min(5vw, 2rem);">
      Current: {{ current }} Highest: {{ highest }} Lowest: {{ lowest }}
    </div>
    <line-chart v-if="!loading" :chart-data="chartData" :options="options" class="small"></line-chart>
    <v-date-picker
        min="2022-02-05"
        :max="new Date().toISOString()"
        v-model="dates"
        range
        @change="load"
    ></v-date-picker>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart"

export default {
  name: "Temperatures",
  components: { LineChart },
  data: () => ({
    loading: true,
    current: 0,
    dates: [],
    chartData: {
      labels: [ 'January', 'February' ],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          title: {
            display: true,
            text: 'Temperature',
          },
          // ticks: {
          //   stepSize: 4
          // }
        },
      },
    },
  }),
  mounted() {
    this.dates.push(new Date().toISOString().split('T')[0])
    this.dates.push(new Date().toISOString().split('T')[0])

    this.load()
  },

  computed: {
    highest() {
      return Math.max(...this.chartData.datasets[0]?.data)
    },
    lowest() {
      return Math.min(...this.chartData.datasets[0]?.data)
    },
  },
  methods: {
    load() {
      this.loading = true
      const from = this.dates.reduce((a, b) => a < b ? a : b)
      const to = this.dates.reduce((a, b) => a > b ? a : b)
      this.$store.state.ky.post('temperature/list', {
        json: {
          from: from,
          to: to,
        },
      }).json().then(res => {
        const labels = []
        const dataset = {
          label: 'Outside',
          backgroundColor: '#f87979',
          fill: false,
          data: [],
        }
        this.current = res.current

        res.temperatures.forEach(data => {
          const date = new Date(data.createdAt)
          const stringDate = date.toLocaleDateString("sk-SK", { hour: 'numeric', minute: 'numeric', second: 'numeric' })
          labels.push(stringDate)
          dataset.data.push(data.value)
        })
        this.chartData.labels = labels
        const datasets = []
        datasets.push(dataset)
        this.chartData.datasets = datasets
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>
.small {
  max-height: 20rem;
}
</style>